import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { TextArea } from '@entur/form';
import { ImageIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import { Link as LinkText } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { textarea } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<TextArea></TextArea>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      TextArea,
      ImageIcon,
      Playground,
      LinkText,
      PageHeader,
      ImportStatement,
      textarea,
      DefaultLayout,
      _frontmatter
    }} props={textarea} mdxType="Playground">
  <TextArea mdxType="TextArea"></TextArea>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "textarea"
    }}>{`TextArea`}</h3>
    <ImportStatement imports="TextArea" mdxType="ImportStatement" />
    <Props of={TextArea} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Text areas er lange tekstfelt som lar brukeren skrive tekst over flere linjer. Tekstfeltet kan justeres i høyden. Bør benyttes med label og eventuelt variant-stiler og validering. Hvis tekststyling er nødvendig, bruk Rich Text Editor for å støtte dette.`}</p>
    <div>
  For tekst som er på en enkelt linje og som ikke overskrider lengden på
  tekstfeltet, bruk heller{' '}
  <LinkText as={Link} to="/komponenter/skjemaelementer/textfield" mdxType="LinkText">
    Text Fields
  </LinkText>
  .
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      